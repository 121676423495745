import { register } from 'numeral'
import { useAuthStore } from '~/store/auth'
import { useRegisterStore } from '~/store/register'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const app = useNuxtApp()
    if (app.$auth.loggedIn) {
      return navigateTo('/app/home')
    }

    const registerStore = useRegisterStore()

    if (!registerStore.oauthId) {
      return navigateTo('/')
    }
  }
})
